import React, { forwardRef } from 'react';
import { IconButtonGeneric } from './IconButtonGeneric';
import type {
  IconButtonProps,
  IconButtonToggleProps,
} from './IconButtonGeneric';

export const IconButton = forwardRef<
  HTMLElement,
  Omit<IconButtonProps, 'checked'>
>((props: Omit<IconButtonProps, 'checked'>, forwardedRef) => {
  return <IconButtonGeneric ref={forwardedRef} {...props} />;
});

export const IconButtonToggle = forwardRef<HTMLElement, IconButtonToggleProps>(
  ({ checked, ...props }: IconButtonToggleProps, forwardedRef) => {
    return (
      <IconButtonGeneric ref={forwardedRef} {...props} checked={checked} />
    );
  },
);

export { type IconButtonProps, type IconButtonToggleProps };
