import { cva } from '@mentimeter/ragnar-tailwind-config';

export const variants = cva(
  [
    'items-center',
    'justify-center',
    'focus-visible:z-[1]',
    'cursor-pointer',
    'transition',
    'ease-in-out',
    'duration-[0.1s]',
    '[&:enabled]:active:scale-90',
    '[&:enabled]:active:opacity-100',
    '[&:enabled]:has-hover:hover:opacity-100',
    '[&:disabled]:data-[has-state="false"]:bg-disabled-strong',
    '[&:disabled]:data-[has-state="false"]:has-hover:hover:bg-disabled-strong',
    '[&:disabled]:data-[has-state="false"]:focus-visible:bg-disabled-strong',
    '[&:disabled]:data-[has-state="false"]:text-on-disabled-strong',
    '[&:disabled]:cursor-not-allowed',
    '[&:disabled]:opacity-100',
    '[&:disabled]:has-hover:hover:opacity-100',
    '[&_svg]:transition-all',
    '[&_svg]:ease-in-out',
    '[&_svg]:duration-[0.1s]',
    '[&:enabled:not(:active)_svg]:data-[has-state="false"]:has-hover:hover:scale-110',
  ],
  {
    variants: {
      variant: {
        primary: [
          'bg-button-primary',
          'text-on-primary',
          '[&:enabled]:data-[has-state="false"]:focus-visible:bg-button-primary-hover',
          '[&:enabled]:data-[has-state="false"]:has-hover:hover:bg-button-primary-hover',
          '[&:enabled]:data-[has-state="false"]:focus-visible:active:bg-button-primary-active',
          '[&:enabled]:data-[has-state="false"]:has-hover:hover:active:bg-button-primary-active',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:bg-button-primary-checked',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:has-hover:hover:bg-button-primary-checked-hover',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:has-hover:hover:active:bg-button-primary-checked-active',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:focus-visible:bg-button-primary-checked-hover',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:text-on-secondary',
        ],
        primaryReverse: [
          'bg-button-primary-reverse',
          'text',
          '[&:enabled]:data-[has-state="false"]:has-hover:hover:active:bg-button-primary-reverse-active',
          '[&:enabled]:data-[has-state="false"]:focus-visible:bg-button-primary-reverse-hover',
          '[&:enabled]:data-[has-state="false"]:focus-visible:active:bg-button-primary-reverse-active',
          '[&:enabled]:data-[has-state="false"]:has-hover:hover:bg-button-primary-reverse-hover',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:bg-button-primary-reverse-checked',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:has-hover:hover:bg-button-primary-reverse-checked-hover',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:has-hover:hover:active:bg-button-primary-reverse-checked-active',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:focus-visible:bg-button-primary-reverse-checked-hover',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:text-on-secondary',
        ],
        secondary: [
          'text',
          '[&:enabled]:data-[has-state="false"]:has-hover:hover:active:bg-button-secondary-active',
          '[&:enabled]:data-[has-state="false"]:focus-visible:bg-button-secondary-hover',
          '[&:enabled]:data-[has-state="false"]:focus-visible:active:bg-button-secondary-active',
          '[&:enabled]:data-[has-state="false"]:has-hover:hover:bg-button-secondary-hover',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:bg-button-secondary-checked',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:border-button-secondary-checked',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:has-hover:hover:bg-button-secondary-checked-hover',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:has-hover:hover:active:bg-button-secondary-checked-active',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:focus-visible:bg-button-secondary-checked-hover',
          '[&:enabled]:aria-[pressed="true"]:shadow-[inset_0_0_0_1px_theme(colors.secondary.DEFAULT)]',
        ],
        tertiary: [
          'bg-button-tertiary',
          'text',
          '[&:enabled]:data-[has-state="false"]:has-hover:hover:active:bg-button-tertiary-active',
          '[&:enabled]:data-[has-state="false"]:focus-visible:bg-button-tertiary-hover',
          '[&:enabled]:data-[has-state="false"]:focus-visible:active:bg-button-tertiary-active',
          '[&:enabled]:data-[has-state="false"]:has-hover:hover:bg-button-tertiary-hover',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:bg-button-tertiary-checked',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:has-hover:hover:bg-button-tertiary-checked-hover',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:has-hover:hover:active:bg-button-tertiary-checked-active',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:focus-visible:bg-button-tertiary-checked-hover',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:text-on-secondary',
        ],
        outline: [
          'bg',
          'text',
          '[&:enabled]:shadow-[inset_0_0_0_1px_theme(textColor.DEFAULT)]',
          'data-[has-state="true"]:shadow-[inset_0_0_0_1px_theme(textColor.DEFAULT)]',
          '[&:enabled]:data-[has-state="false"]:has-hover:hover:active:bg-button-outline-active',
          '[&:enabled]:data-[has-state="false"]:focus-visible:bg-button-outline-hover',
          '[&:enabled]:data-[has-state="false"]:focus-visible:active:bg-button-outline-active',
          '[&:enabled]:data-[has-state="false"]:has-hover:hover:bg-button-outline-hover',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:bg-button-outline-checked',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:has-hover:hover:bg-button-outline-checked-hover',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:has-hover:hover:active:bg-button-outline-checked-active',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:focus-visible:bg-button-outline-checked-hover',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:border-text',
          '[&:enabled]:aria-[pressed="true"]:data-[has-state="false"]:text',
        ],
        negativeWeak: [
          'bg-button-negative-weak',
          'text-on-negative-weakest',
          '[&:enabled]:data-[has-state="false"]:has-hover:hover:bg-button-negative-weak-hover',
          '[&:enabled]:data-[has-state="false"]:focus-visible:bg-button-negative-weak-hover',
          '[&:enabled]:data-[has-state="false"]:focus-visible:active:bg-button-negative-weak-active',
          '[&:enabled]:data-[has-state="false"]:has-hover:hover:active:bg-button-negative-weak-active',
        ],
      },
      borderRadius: {
        medium: ['rounded-lg'],
        full: ['rounded-full'],
      },
      size: {
        large: ['p-[calc(theme(spacing.3)_+_2px)]'],
        default: ['p-3'],
        compact: ['p-2'],
      },
    },
    defaultVariants: {
      variant: 'primary',
      borderRadius: 'medium',
      size: 'default',
    },
  },
);
