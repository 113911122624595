import React from 'react';
import { getIsMemberLite, useUser } from '@mentimeter/user';
import { RequestUpgradePopoverContent } from './popover/RequestUpgradePopoverContent';
import {
  REQUEST_UPGRADE_DESCRIPTION,
  REQUEST_UPGRADE_TITLE,
} from './constants';
import type { RequestUpgradeComponentProps } from './types';
import { requestUpgrade } from './requestUpgrade';

export function RequestUpgradePopover({
  title = REQUEST_UPGRADE_TITLE,
  description = REQUEST_UPGRADE_DESCRIPTION,
  trackingContext,
  trackingPlacement,
  children,
}: RequestUpgradeComponentProps) {
  const { user } = useUser();
  const isMemberLite = getIsMemberLite(user);

  if (!isMemberLite) return children;

  return (
    <RequestUpgradePopoverContent
      title={title}
      description={description}
      onRequestUpgrade={(message) =>
        requestUpgrade({
          message,
          trackingContext,
          trackingPlacement,
        })
      }
    >
      {children}
    </RequestUpgradePopoverContent>
  );
}
