import { cva } from '@mentimeter/ragnar-tailwind-config';

export const variants = cva(
  [
    'z-[1]',
    'align-center',
    'justify-center',
    'flex-auto',
    '[&_svg]:w-full',
    '[&_svg]:h-full',
  ],
  {
    variants: {
      size: {
        large: ['w-[theme(fontSize.150)]', 'h-[theme(fontSize.150)]'],
        default: ['w-[theme(fontSize.100)]', 'h-[theme(fontSize.100)]'],
        compact: ['w-[theme(fontSize.100)]', 'h-[theme(fontSize.100)]'],
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
);
